import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { UserPermissions_role } from './constant';
import { CustomAlertService } from './custom-alert.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    permission: any;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private customAlert: CustomAlertService
    ) {
        this.permission = UserPermissions_role;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        // console.log(currentUser);
        if (currentUser && currentUser.access_token && currentUser.id_token) {
            // role not authorised so redirect to home page
            // this.router.navigate(['/']);
            return true;
        }
        // not logged in so redirect to login page with the return url
        else{
            let qp= {};
            qp['returnUrl'] = state.url;
            // if(route.queryParams?.email && route.queryParams?.access_token && route.queryParams?.id_token){
            //     qp['email'] = route.queryParams?.email;
            //     qp['access_token'] = route.queryParams?.access_token;
            //     qp['id_token'] = route.queryParams?.id_token;
            // }
            if(route.queryParams?.code){
                qp['code'] = route.queryParams?.code;
            }
        this.router.navigate(['/user/signin'], { queryParams: qp });
        return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.access_token) {
            let userInfo = JSON.parse(localStorage.getItem("loginInfo"));
            //Data hook
            if (route["_routerState"].url.includes("/app-builder/redditconfig")) {
                if (!this.check_dh_access("Reddit")) {
                    return false;
                }
            }
            else if (route["_routerState"].url.includes("/app-builder/twitterconfig")) {
                if (!this.check_dh_access("Twitter")) {
                    return false;
                }
            }
            else if (route["_routerState"].url.includes("/app-builder/csvconfig")) {
                if (!this.check_dh_access("CSV")) {
                    return false;
                }
            }
            else if (route["_routerState"].url.includes("/app-builder/naviga")) {
                if (!this.check_dh_access("Naviga")) {
                    return false;
                }
            }
            else if (route["_routerState"].url.includes("/app-builder/pdftotext")) {
                if (!this.check_dh_access("Doc Uploader")) {
                    return false;
                }
            }

            //Knowledge function
            if (route["_routerState"].url.includes("/app-builder/topicconfig")) {
                if (!this.check_kf_access("Topic Classification")) {
                    return false;
                }
            }
            else if (route["_routerState"].url.includes("/app-builder/sentimentconfig")) {
                if (!this.check_kf_access("Sentiment Analysis")) {
                    return false;
                };
            }
            else if (route["_routerState"].url.includes("/app-builder/ner")) {
                if (!this.check_kf_access("NER")) {
                    return false;
                }
            }


            return true;
        }
    }

    check_dh_access(datahook) {
        let userInfo = JSON.parse(localStorage.getItem("loginInfo"));
        if (userInfo.user_role.enable_features.permissions.find(a => a == this.permission["create_datahook"]) == undefined) {
            this.customAlert.open("You are not authorized!", "Error")
            return false;
        }
        else if (userInfo.user_role.enable_features.data_hooks.find(a => a == datahook) == undefined) {
            this.customAlert.open("You are not authorized!", "Error")
            return false;
        }
        return true;
    }

    check_kf_access(kf) {
        let userInfo = JSON.parse(localStorage.getItem("loginInfo"));
        if (userInfo.user_role.enable_features.permissions.find(a => a == this.permission.use_kf) == undefined) {
            this.customAlert.open("You are not authorized!", "Error")
            return false;
        }
        else if (userInfo.user_role.enable_features.knowledge_function.find(a => a == kf) == undefined) {
            this.customAlert.open("You are not authorized!", "Error")
            return false;
        }
        return true;
    }
}