<mat-form-field appearance="outline" [ngClass]="readonly?'customDisableClass':''">
    <mat-label>{{placeholder}}</mat-label>
    <ng-container *ngIf="!hideClear">
        <i class="fa fa-times cross-btn" [hidden]="hidden" *ngIf="value && value.length > 0 && inputList && inputList.length > 0 && !readonly && !disabled" (click)="stopClick($event);writeValue(null);changes.emit(null)"></i>
    </ng-container>
    <mat-select [(ngModel)]="value" [required]="required" [name]="name" [disabled]="disabled" [multiple]="multiple"
        (openedChange)="openChangeEmit($event)" (selectionChange)="selectionChangeEmit($event)">
        <mat-option [hidden]="readonly">
            <!-- <ngx-mat-select-search [placeholderLabel]="inputList?.length > 0 ? 'Search' : 'No records found'" [noEntriesFoundLabel]="'No entries found'"
                [formControl]="filteredCodesControl"></ngx-mat-select-search> -->
        </mat-option>
        <br *ngIf="selectAll && inputList && !(inputList.length === 0)">
        <mat-checkbox id="checkSelectAll" [disabled]="readonly" *ngIf="selectAll && inputList && !(inputList.length === 0)" #checkBox [formControl]="selectAllBox"
            (change)="selectAllEvent($event.checked)" #selectAllCheckbox>
            Select All
        </mat-checkbox>
        <mat-option [disabled]="readonly || code[bindDisable]" matTooltip="{{code[bindName]}}" matTooltipPosition="left" *ngFor="let code of filteredCodes | async;" [value]="code[bindValue]">
            {{code[bindName]}}
            <span class="float-right text-muted">{{code[oName]?code[oName]:''}}</span>
        </mat-option>
    </mat-select>
</mat-form-field>