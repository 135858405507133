import { Component, Input, forwardRef, OnInit, OnChanges, Output, EventEmitter, ElementRef, ViewChild, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgModel } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { SearchByKeyFilterPipe } from 'src/app/modules/global/pipe/dynamic-filter.pipe';
@Component({
  selector: 'app-ngx-select',
  templateUrl: './ngx-custom.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxCustomComponent),
      multi: true
    },
    SearchByKeyFilterPipe
  ],
  styleUrls: ['./ngx-custom.component.scss']
})
export class NgxCustomComponent implements ControlValueAccessor, OnChanges {
  @Input() name: string;
  @Input('bindValue') bindValue: string;
  @Input('bindName') bindName: string;
  @Input('optionalName') oName: string;
  @Input('disableField') bindDisable: string;
  @Input('value') val: string;
  @Input() disabled = false;
  @Input() multiple = false;
  @Input() placeholder: string;
  @Input() inputList: any;
  @Input() readonly: boolean = false;
  @Output() openedChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() changes: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectAll: boolean;
  @Input() required: boolean = false;
  @Input() hidden: boolean = false;
  @Input() hideClear: boolean = false;
  @ViewChild('checkBox') checkBox: ElementRef;
  @Input() inAlphabeticalOrder: boolean;
  @HostBinding('class.ngxselect') showclass: boolean = true;
  @ViewChild(NgModel) mySelectModel: NgModel;
  constructor(private _searchByKeyFilter: SearchByKeyFilterPipe) { }
  filteredCodesControl = new FormControl();
  selectAllControl = new FormControl();
  selectAllBox = new FormControl();
  public filteredCodes: ReplaySubject<any> = new ReplaySubject<any>(1);
  listLength: number;
  backupVal: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  ngOnChanges() {
    if (this.inputList){
      this.listLength = this.inputList.length;
      if(this.inAlphabeticalOrder){
        var that = this;
        this.inputList.sort((a, b) => {
          return String(a[that.bindName]).localeCompare(String(b[that.bindName]));
        });
      }
    }
    this.selectAllCheck(this.value);
    if(this.backupVal && !this.value){
        if(this.validateValue(this.backupVal))
          this.writeValue(this.backupVal);
    }
    this.selectAll = this.selectAll ? this.selectAll : this.multiple;
    this.filteredCodes.next(this.inputList);
    this.filteredCodesControl.valueChanges
      .subscribe(() => {
        this.filteredCodes.next(this._searchByKeyFilter.transform(this.inputList, this.filteredCodesControl.value, this.bindName));
      });
  }
  selectAllEvent(checked) {
    if (!this.readonly){
      if (checked) {
        this.value = this.inputList.map(key => key[this.bindValue]);
      }
      else {
        this.value = null;
      }
      this.changes.emit({value: this.value});
    }
  }
  get value() {
    return this.val;
  }
  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  writeValue(value) {
    if (value && value.length === 0)
      this.selectAllBox.setValue(false);
    this.selectAllCheck(value);
    if (value) {
      if (this.readonly || this.disabled || this.validateValue(value))
      {
        this.value = value;
        this.backupVal = undefined;
      }
    }
    if (value === null)
    {
      this.mySelectModel.reset();
      this.value = value;
      this.clear.emit(null);
    }
  }

  stopClick(event){
    event.stopPropagation();
  }

  validateValue(value) {
    let that = this;
    if (!value)
      return false;
    if (typeof value === 'object')
      for (let val of value) {
        if (that.inputList && that.inputList.filter(key => key[this.bindValue] === val).length === 0) {
          this.backupVal = value;
          return false;
        }
      }
    if (typeof value === 'string' || typeof value === 'number')
      if (that.inputList && that.inputList.filter(key => key[this.bindValue] === value).length === 0) {
        this.backupVal = value;
        return false;
      }
    
    if (!this.inputList || this.inputList.length === 0) {
      this.backupVal = value;
      return false;
    }
    return true;
  }


  openChangeEmit(event) {
    this.openedChange.emit(event);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  selectionChangeEmit(event) {
    this.selectAllCheck(event.value);
    this.changes.emit(event);
  }

  selectAllCheck(value){
    if(this.selectAll && this.inputList && this.inputList.length !== 0)
    {
      if(value && value.length === this.inputList.length)
      {
        this.selectAllBox.setValue(true);
      }
      else{
        this.selectAllBox.setValue(false);        
      }
    }
  }
}