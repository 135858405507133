<h1 mat-dialog-title>
    {{title}}
</h1>

<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onConfirm()" cdkFocusInitial>{{confirmMessage}}</button>
    <button mat-button (click)="onCancel()">{{denyMessage}}</button>
</div>