import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { forgetPwdUrl, resetPwdUrl, signinUrl, signupUrl } from './global-data-urls';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient, private router:Router) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    // console.log(this.currentUserSubject.value);
    
    return this.currentUserSubject.value;
  }

  signin(payload) {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded')
    const formData = new FormData();
    formData.append('username',payload.username);
    formData.append('password',payload.password);
    return this.http.post<any>(signinUrl,formData,{headers})
      .pipe(map((data:any) => {
        return data;
      }));
  }

  signup(payload) :Observable<any>{
    return this.http.post<any>(signupUrl,payload);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(["/user/signin"]);
    location.reload();
  }

  forgot(username: string) {

    return this.http.post<any>(forgetPwdUrl, { //CHANGE URL HERE
      "email": username,
    })
  }

  reset(newPassword, token) {

    return this.http.post<any>(resetPwdUrl, { //CHANGE URL HERE
      "token": token,
      "password": newPassword,
    })
  }

  change(newPassword, oldPassword, username) {

    return this.http.post<any>(`authv2/changePassword`, { //CHANGE URL HERE
      "usernameOrEmail": username,
      "old_password": oldPassword,
      "new_password": newPassword,
    })
      .pipe(map(data => {
        if (data && data['s'] == 1) {
          return "password updated successfully";
        } else return "e";

      }), catchError((err, caught) => {
        if (err == "Invalid Token") {
          return "i"
        } else
          return "e";
      }));
  }



}
