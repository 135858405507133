import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderState } from './loader';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  show = false;
  private subscription: Subscription;
  color = 'primary';
  mode = 'indeterminate';
  constructor(private loaderService: LoaderService) {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = true;//state.show;
      });
  }
  ngOnInit() {

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
