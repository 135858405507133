import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from "primeng/multiselect";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DropdownModule,
    MultiSelectModule
  ],
  exports: [
    DropdownModule,
    MultiSelectModule
  ]
})
export class PrimengModule { }
