import { Component,Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-common-confirmation-dialog',
  templateUrl: './common-confirmation-dialog.component.html',
  styleUrls: ['./common-confirmation-dialog.component.scss']
})
export class CommonConfirmationDialogComponent implements OnInit {

  title: any;
  message: any;
  confirmMessage: any;
  denyMessage:any;
  constructor(public dialogRef: MatDialogRef<CommonConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;

    this.confirmMessage = data.confirmValue ;
    this.denyMessage = data.denyValue
  }

  ngOnInit(): void {
  }

  onConfirm(){
    // this.dialogRef.close(this.data.confirmKey);
    this.dialogRef.close('Yes');
  }

  onCancel(){
    // this.dialogRef.close(this.data.denyKey);
    this.dialogRef.close('No'); 
  }


}

export interface CommonDialogModel {
  title : string,
  message : string,
  confirmKey?: string,
  confirmValue : string,
  denyKey?: string,
  denyValue : string
}