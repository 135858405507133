<mat-sidenav #rightSidenav mode="side" class="rightSidenav" position="end" [fixedInViewport]="true" [fixedTopGap]="250">
   <button mat-icon-button class="close-button">
      <mat-icon class="blue-cross" (click)="close()">close</mat-icon>

   </button>
   <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Notifications">
         <span>
            <button mat-icon-button class="read-all" (click)="readAllNotifications()">Mark all as read</button>
            <button mat-icon-button class="clear-all" (click)="clearNotifications()">Clear all</button>
         </span>
         <div class="no-notifcations" *ngIf = "flag">
            No Notifications Yet!
         </div>
         <mat-card *ngFor="let notifyList of notification " [ngClass]="notifyList.is_read === true ? 'card-read' : 'card-unread'">
           
            <div (click)="readNotifications(notifyList.id)"  fxLayout.gt-sm ="row" fxLayout="column" fxFlex="100" class="notify-card">
               <div >
                  <mat-icon  class="green-checkmark">check_circle</mat-icon>
               </div>
               <div style="width: 70%">
                  <mat-card-title> {{notifyList.notification_msg}}</mat-card-title> 
                  <mat-card-subtitle>environment: <span class="env-name">{{notifyList.env_name}}</span></mat-card-subtitle>
                  
               </div>
               <div style="width: 25%">
                  <mat-card-actions >{{notifyList.created_at | dateAgo}}</mat-card-actions>
               </div>
            </div>
            </mat-card>
      </mat-tab>
      <mat-tab label="History"></mat-tab>

   </mat-tab-group>

</mat-sidenav>