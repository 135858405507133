import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-common-error-dialog',
  templateUrl: './common-error-dialog.component.html',
  styleUrls: ['./common-error-dialog.component.scss']
})
export class CommonErrorDialogComponent implements OnInit {

  title: any;
  message: any;
  confirmMessage: any;
  denyMessage:any;

  constructor(public dialogRef: MatDialogRef<CommonErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonErrorDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;

    this.confirmMessage = data.confirmValue ;
    this.denyMessage = data.denyValue

     }

  ngOnInit(): void {
  }

  onConfirm(){
    // this.dialogRef.close(this.data.confirmKey);
    this.dialogRef.close('Yes');
  }

  onCancel(){
    // this.dialogRef.close(this.data.denyKey);
    this.dialogRef.close('No'); 
  }

}

export interface CommonErrorDialogModel {
  title : string,
  message : string,
  confirmKey?: string,
  confirmValue : string,
  denyKey?: string,
  denyValue : string
}
