<div class="error-container">
    <div class="error-message-container">
        <div class="sub-header">
            <span class="error-sub-header">
                Error
            </span>
            <span class="spacer"></span>
            <span (click)="onCancel()" class="close-icon">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <h2 mat-dialog-title>
            {{title}}
        </h2>    
        <div class="content">
            <p>{{message}}</p>
        </div>
        
    </div>
    <div class="spacer"></div>
    <div class="button-container">
        <button mat-flat-button (click)="onCancel()">{{denyMessage}}</button>
    
        <button mat-flat-button class="confirm-btn" (click)="onConfirm()" cdkFocusInitial>{{confirmMessage}}</button>
    </div>    
</div>
