export const UserPermission = {
    // "view_app": "View an app",
    // "OIG7K5DV": "Create a data hook",
    // "TSODDGHP": "Create a template",
    // "F03ISE1O": "Create an app",
    // "S3QP007X": "Create an environment",
    // "WOJK56GA": "Train a model",
    // "O2VGJKMD": "Use a knowledge function",
    // "5NDX270I": "Validate a template",
    // "9LMZXST6": "View glass box screen",
    // "create_new_client_group": "Create a new client group",
    // "create_new_user": "Create a new user for environment",
    // "view_dashboard": "View a dashboard"

    create_new_client_group: "Create a new client group",
    create_new_user: "Create a new user for environment",
    create_datahook: "Create a data hook",
    create_template: "Create a template",
    create_app: "Create an app",
    create_env: "Create an environment",
    train_model: "Train a model",
    use_kf: "Use a knowledge function",
    validate_template: "Validate a template",
    view_glassbox: "View glass box screen",
    view_dashboard: "View a dashboard"
};

export const UserPermissions_role = {
    create_new_client_group: "create_new_client_group",
    create_env: "create_env",//done
    create_new_user: "create_new_user",//done
    create_datahook: "create_datahook",//done
    use_kf: "use_kf",//done
    create_template: "create_template",//done
    create_app: "create_app",
    validate_template: "validate_template",//done
    train_model: "train_model",//done
    view_glassbox: "view_glassbox",//done
    view_dashboard: "view_dashboard"
};
