import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFilter'
})
export class CustomFilterPipe implements PipeTransform {
  transform(items: any, keyword: any): any {
    if (!items)
      return items;
    const regExp = new RegExp(keyword, "gi");
    const check = obj => {
      if (obj !== null && typeof obj === "object") { return Object["values"](obj).some(check) }
      if (Array.isArray(obj)) { return obj.some(check) }
      return (typeof obj === "string" || typeof obj === "number") && regExp.test(String(obj));
    }
    return items.filter(check);
  }
}

@Pipe({
  name: 'customKeyFilter'
})
export class CustomKeyFilterPipe implements PipeTransform {
  transform(value: Array<any>, keys: string, term: string) {
    if (!term) return value;
    return (value || []).filter(x => keys.split(',').some(key => x.hasOwnProperty(key) && new RegExp(term, 'gi').test(x[key])));
  }
}


@Pipe({ name: 'searchByKeyFilter' })
export class SearchByKeyFilterPipe implements PipeTransform {
  transform(items: any, searchText: any, key: string): any {
    if (searchText == null) return items;
    return items.filter((search) => {
      if (search[key] != null) { return search[key].toLowerCase().indexOf(searchText.toLowerCase()) > -1 }
    })
  }
}