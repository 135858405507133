import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/authguard';

const routes: Routes = [{
  path: 'user',
  loadChildren:  () => import('./modules/user/user.module').then(m => m.UserModule),
}, {
  path: '',
  loadChildren: () => import('./modules/layout/layout.module').then(m => m.MainLayoutModule),
  canActivate: [AuthGuard]
},// otherwise redirect to home
{ path: '**', redirectTo: '' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
